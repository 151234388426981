import styled from 'styled-components'


interface Props {
  title: string
  image?: string | undefined
  description?: string | undefined
  link?: string
  reviewLink?: string
  reviewDescription?: string
}

const Catalog = (props: Props) => {
  const openLink = () => {
    if (props.link)
      window.open(props.link, '__blank')
  }

  return (
    <Container>
      <Title>{props.title}</Title>
      <Image src={props.image} onClick={() => openLink()} />
      <Description>{props.description}</Description>
      {props.reviewLink && <Link href={props.reviewLink} target='_blank'>{props.reviewDescription}</Link>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  margin: 25px;
`

const Title = styled.div`
  color: white;
  font-weight: bold;
`

const Image = styled.img`
  margin-top: 10px;
  border-radius: 10px;
  cursor: pointer;
`

const Description = styled.div`
  color: white;
  margin-top: 10px;
  text-align: justify;
`

const Link = styled.a`
  width: fit-content;
  margin-top: 10px;
  color: white;
  font-weight: bold;
`

export default Catalog