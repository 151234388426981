import styled from 'styled-components'
import Catalog from './components/Catalog'


const App = () => {
  return (
    <Container>
      <CatalogItems>
        <Catalog
          title='Best Throw'
          image={require('./assets/images/best_throw.jpg')}
          description='Best Throw is a fun game for the whole family. Your goal is to launch the disc in the right colors. The farther you go, the more challenging it will get.'
          link='https://play.google.com/store/apps/details?id=com.allakore.bestthrow' />

        <Catalog
          title='Fast Game - Booster'
          image={require('./assets/images/fast_game.png')}
          description='With this app you will be able to improve the performance of your installed games. Its simple and beautiful interface makes it very easy to use.'
          link='https://play.google.com/store/apps/details?id=com.allakore.fastgame'
          reviewLink='https://taimienphi.vn/download-fast-game-94589'
          reviewDescription='Reviewed by taimienphi.vn' />

        <Catalog
          title='Snake Runner'
          image={require('./assets/images/snake_runner.png')}
          description='Test your dexterity with this simple and fun game.'
          link='https://play.google.com/store/apps/details?id=com.allakore.snakerunner' />

        <Catalog
          title='Video Cast'
          image={require('./assets/images/video_cast.png')}
          description="With this app, you'll be able to cast your videos, movies and series to your Chromecast simply and quickly. Totally free and without ads."
          link='https://play.google.com/store/apps/details?id=com.allakore.videocast' />

        <Catalog
          title='Super Flappy Dragon'
          image={require('./assets/images/super_flappy_dragon.webp')}
          description='You will play with a dragon, in which the objective is to collect as many coins as possible.'
          link='https://play.google.com/store/apps/details?id=com.allakore.superflappydragon' />

        <Catalog
          title='SWAP - No ROOT'
          image={require('./assets/images/swap_no_root.png')}
          description='With this application, you will be able to create a SWAP file on your device.'
          link='https://play.google.com/store/apps/details?id=com.allakore.swapnoroot' />

        <Catalog
          title='Swapper - ROOT'
          image={require('./assets/images/swapper.png')}
          description='With Swapper, you will be able to create SWAP Memory for your Android.'
          link='https://play.google.com/store/apps/details?id=com.allakore.swapper' />
      </CatalogItems>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
  background-color: #282c34;
  align-items: center;
  justify-content: center; 
`

const CatalogItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 30px;
  padding-bottom: 30px;
`

export default App